import { useEffect } from 'react';
import { useDisclosure } from "@mantine/hooks";
import {
  Drawer,
  Grid,
  Text,
  Button
} from '@mantine/core';
import { Link } from "react-router-dom";
import { cookie } from '../../lib/helpers';

export default function CookieDrawer({ isMobile }) {
  const [ opened, { open, close}] = useDisclosure(false);

  const cookieValue = cookie('idrawer');

  useEffect(() => {
    if (cookieValue === undefined) open();

    return close;
  }, [cookieValue, open, close]);
  
  const hideDrawer = () => {
    document.cookie = cookie('idrawer', '1;SameSite: None; max-age=8640000');
    close();
  }

  return (
    <Drawer 
      opened={opened} 
      onClose={hideDrawer} 
      title="Notice: We're getting a makeover!" 
      position="bottom"
      size={isMobile ? "48%" : "30%"}
      overlayProps={{ opacity: 0.5, blur: 4 }}
    >
      <Grid>
        <Grid.Col span={12} md={10}>
          <Text mb={15}>
            <strong>Please note:</strong> Event creation is temporarily disabled until October 1st, 2024. We're excited to share a bunch of new features to make your event experience even better!
          </Text>
          <Text mb={10}>
            Plainstage stores some content and settings in your browser to improve the experience of using the site. We don't have ads, but we do use third-party services such as analytics and payments, which may also store content in your browser. To fully understand the information we store, please visit our <Link to="/privacy-policy">Privacy Policy.</Link>
          </Text>
        </Grid.Col>
        <Grid.Col span={12} md={2}>
          <Button color="orange" onClick={hideDrawer}>I understand</Button>
        </Grid.Col>
      </Grid>
    </Drawer>
  )
}